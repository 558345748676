import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import ArticleResult from "../components/articleresult";
import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios";

const endpoint = process.env.GATSBY_PRISMIC_SEARCH_API;
const windowGlobal = typeof window !== "undefined";

const ResultPage = ({ location }: any) => {
  const [articles, setArticles] = useState<any>([]);
  let query = windowGlobal ? location.state.query : "";

  useEffect(() => {
    if (query) {
      // Instantiating and fetching masterRef from the Prismic API
      let masterRef = "YGT3exMAACMAXJMx";
      let urlFriendlyQuery = query.replace(" ", "+");
      axios
        .get("https://flapmax-blog.cdn.prismic.io/api/v2")
        .then((data) => (masterRef = data.data.refs[0].ref))
        .catch((err) => console.log(err));
      // Sending our content test to the Prismic search API
      axios
        .get(
          `https://flapmax-blog.prismic.io/api/v2/documents/search?ref=${masterRef}&q=[[at(document.type%2C+"article")]]&q=[[fulltext(document, "${urlFriendlyQuery}")]]&access_token=${process.env.GATSBY_PRISMIC_ACCESS_TOKEN}`
        )
        .then((data) => {
          setArticles(data.data.results);
        })
        .catch((err) => console.log(err));
    }
  }, [query]);

  return (
    <Layout>
      <SEO title="Search Results" description="" lang="en" meta={[]} />
      <section className="top">
        <div className="container">
          <h2>Results For "{query}"</h2>
          <div className="col-lg-4">
            {articles.map((item: any) => (
              <ArticleResult data={item} key={item.uid} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicArticle(filter: { data: { title: { text: { regex: "" } } } }) {
      edges {
        node {
          tags
          uid
          data {
            date
            header_image {
              url
            }
            header_image_caption {
              text
            }
            title {
              text
            }
            author {
              text
            }
          }
        }
      }
    }
  }
`;

export default ResultPage;
