import { Link } from "gatsby";
import React from "react";
import { formatDate } from "../utils/formatDate";
import Tag from "./tag";

interface Props {
  data: {
    uid: string;
    data: {
      tags: string;
      header_image: { url: string };
      date: string;
      updatedDate?: Date;
      author: [{ type: string; text: string; spans: [] }];
      title: [{ type: string; text: string; spans: [] }];
    };
  };
}

const ArticleResult = ({ data }: Props) => {
  const tags = data.data.tags.split(", ");
  const date = new Date(data.data.date);
  return (
    <Link
      className="article-preview mini text-decoration-none"
      to={`/articles/${data.uid}`}
    >
      <div className="img-div">
        <img className="img-fluid" alt="" src={data.data.header_image.url} />
      </div>
      <div className="text-div">
        <div className="date-author-div">
          <p>
            {formatDate(date)}{" "}
            {data.data.updatedDate ? (
              <i>Updated: {formatDate(data.data.updatedDate)}</i>
            ) : (
              ""
            )}
          </p>
          <p className="divider">|</p>
          <p>{data.data.author[0].text}</p>
        </div>
        <h3>{data.data.title[0].text}</h3>
        <div className="category-container">
          {tags.map((tag) => (
            <Tag text={tag} key={tag} />
          ))}
        </div>
      </div>
    </Link>
  );
};

export default ArticleResult;
